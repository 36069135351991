import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PrismicRichText } from '@prismicio/react';
import { format } from 'date-fns';
import { htmlSerializer } from '../utils/htmlSerializer';
import CompactLayout from '../components/compactLayout';
import { RichText } from 'prismic-reactjs';

export const NewsPost = ({ data }) => {
  const post = data.prismicNewspost.data;
  const lastPublicationDate = data.prismicNewspost.last_publication_date;
  const headerImageFluid = post.header_image.gatsbyImageData;
  const headerImageAlt = post.header_image.alt;
  const headerImageUrl = post.header_image.url;

  return (
    <CompactLayout pageTitle={RichText.asText(post.title.richText)}>
      <header className='flex flex-col mx-auto max-w-4xl'>
        <div>
          <Link to='/news'>
            <div className='my-8 text-opaqueblue hover:text-gray-800 md:text-lg'>
              <span>News and Announcements</span>
            </div>
          </Link>
        </div>
        <div className='mb-8'>
          <div className='mt-4 mb-2 text-lg text-denimblue md:text-2xl'>
            <PrismicRichText
              field={post.title.richText}
              components={htmlSerializer}
            />
          </div>
          <div className='text-sm text-gray-700 md:text-base'>
            <span>
              Posted on {format(new Date(lastPublicationDate), 'MMMM dd, yyyy')}
            </span>
          </div>
        </div>
        <div className='flex w-full items-center'>
          <a
            href={headerImageUrl}
            rel='noopener noreferrer'
            target='_blank'
            className='mx-auto w-3/4 rounded shadow-lg lg:w-2/3'
          >
            <GatsbyImage image={headerImageFluid} alt={headerImageAlt} />
          </a>
        </div>
      </header>
      <div className='mb-8 pt-8 max-w-4xl mx-auto text-gray-800'>
        <PrismicRichText
          field={post.content.richText}
          components={htmlSerializer}
        />
      </div>
    </CompactLayout>
  );
};

export default NewsPost;

export const query = graphql`
  query NewsPost($uid: String!) {
    prismicNewspost(uid: { eq: $uid }) {
      uid
      last_publication_date
      data {
        title {
          richText
        }
        header_image {
          alt
          url
          gatsbyImageData
        }
        content {
          richText
        }
      }
    }
  }
`;
